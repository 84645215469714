.hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;


}
.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}
.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42 ;
    width: fit-content;
    padding: 20px 13px;
    border-radius: 4rem;
    text-transform: uppercase;
    color: white;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad>div{
    position: absolute;
   background-color: var(--orange); 
   width: 4rem;
   height: 80%;
   border-radius: 3rem;
   left: 8px;
   z-index: 1;
}
.hero-text{
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    gap: 1.5rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-overflow: inherit;
}
.hero-text>div:nth-of-type(3){
    width: 80%;
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 1px;
    text-transform: none;
  }
  .figures{
    display: flex;
    gap: 2rem;
    margin-top: 1.7rem;
  }
  .figures>div{
    display: flex;
    flex-direction: column;
  }
  .figures>div>span:nth-of-type(1){
    color: rgb(255, 255, 255);
    font-size: 2rem;
    margin-left: 1rem;
  }
  .figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
  }
  .hero-button{
    display: flex;
    gap: 1rem;
    font-weight: normal;
  }
  .hero-button>button:nth-of-type(1){
    background-color: var(--orange);
    width: 8rem;
    color: white;
  }
  .hero-button>button:nth-of-type(2){
    background-color: transparent;
    width: 8rem;
    color: white;
    border: 2.4px solid var(--orange);
  }
  .right-h>.btn{
    position: absolute;
    margin-top: 2rem;
    right: 3rem;
    color: black;
  }
  .heart-rate{
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--darkGrey);
    flex: start;
    position: absolute;
    right: 4rem;
    top: 7rem;
    border-radius: 5px;
  }
  .heart-rate>:nth-child(2){
    color: var(--gray);
  }
  .heart-rate>:nth-child(3){
    color: white;
    font-size: 1.5rem;
  }
  .heart-rate>img{
    width: 2.8rem;
  }
  .hero_image{
    position: absolute;
    width: 23rem;
    top: 10rem;
    right: 10.5rem;
  }
  .hero_image_back{
    position: absolute;
    z-index: -1;
    right: 24.5rem;
    top: 10rem;
  }
  .calories{
    position: absolute;
    display: flex;
    gap: 2rem;
    top: 33rem;
    right: 46rem;
    background-color: var(--caloryCard);
    padding: 1rem;
    width: fit-content;
    border-radius: 5px;
  }
  .calories> img{
    width: 3rem;
  }
  .calories>:nth-child(2){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 7rem;
  }
  .calories>div>span:nth-of-type(1){
     color: var(--gray);
     font-size: 1rem;
  }
  .calories>div:nth-child(2){
    color: white;
    font-size: 1.5rem;
  }
  .hero-blur{
    width: 28rem;
    height: 30rem;
    left: 0;
  }
  @media  screen and (max-width: 768px)
   {
    .hero{
      flex-direction: column;
    }  
    .hero-blur{
      width: 14rem;
    }
    .the-best-ad{
      margin-top: 0;
      font-size: small;
      align-self: center;
      transform: scale(0.8);
    }
    .hero-text{
      font-size: xx-large;
      align-items: center;
      justify-content: center;
    }
    .hero-text>div:nth-of-type(3){
      font-size: small;
      font-weight: 200;
      letter-spacing: 1px;
      text-align: center;
    }
    .hero-button{
      justify-content: center;
    }
    .figures>div>span:nth-of-type(1){
      font-size: large;
    }
    .figures>div>span:nth-of-type(2){
      font-size: small;
    }
    .right-h{
      position: relative;
      background: none;
    }
    .heart-rate{
      left: 1rem;
      top: -1rem;
      transform: scale(0.85);
    }
    .calories{
      position: relative;
      top: 5rem;
      left: 2rem;
      width: 11rem;
      height: 3rem;
    }
    .calories>img{
      width: 2rem;
    }
    .calories>div>:nth-child(2){
      color: white;
      font-size: 1rem;
      margin-bottom: -0.3rem;
    }
    .calories>div>:nth-child(1){
      color: white;
      margin-top: -0.3rem;

    }
    .hero_image{
      position: relative;
      width: 15rem;
      left: 7rem;
      top: 4rem;
      align-self: centre;   
     }
     .hero_image_back{
      width: 15rem;
      left: 3rem;
      top: 0rem;
      transform: scale(0.7);
     }
  }