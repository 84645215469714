.right-j{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      flex-direction: column;
}
.right-j>:nth-child(1){
    text-transform: uppercase;
    font-size: 2.4rem;
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--orange);
    margin-bottom: 2rem;
    font-weight: bold;
}
.email-container{
    display: flex;
    gap: 1rem;
    background-color: grey;
    padding: 0.5rem;
    width: 19rem;
}
.email-container>:nth-child(1){
    background-color: transparent;
    border: none;
    color: white;
}
::placeholder{
    color: white;
}
.right-j>span>:nth-child(1){
    width: 19.7rem;
    border-width: 2px;
    margin-top: -0.1rem;
}
.btn-j{
    background-color: var(--orange);
    color: white; 
}

@media  screen and (max-width: 768px){
    .right-j{
        flex-direction: column;
    }
    .right-j>:nth-child(1){
        font-size: x-large;
        text-align: center;
    }
    .right-j>span>:nth-child(1){
       width: 14rem;
       margin-top: 0.5rem; 
    }
    .email-container{
        transform: scale(0.78);
    }
}