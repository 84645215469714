 .plans-container{
    width: 100%;
}
.programs-header{
    display: flex;
    gap: 5rem;
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: bold;
    justify-content: space-around;
    font-style: italic;
    padding-right: 2.5rem;
} 
.plans-container{
    margin-top: -4rem;
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>svg{
    fill: black;
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 0.8rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.plans>:nth-child(2)>svg{
    fill: rgb(34, 210, 249);
}
.plans>:nth-child(2)>button{
    color: orange;
}
.plans-blur-1{
    width: 32rem;
    height: 23rem;
    left: 6.5rem;
    top: 12rem;
}
.plans-blur-2{
    width: 32rem;
    height: 23rem;
    right: 5rem;
    top: 12rem;
}


@media  screen and (max-width: 768px){
    .plans-container{
        flex-direction: column;
    }
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        margin-top: 1.5rem;
    }
    .programs-header>span{
        font-size: x-large;
    }
    .plans{
        flex-direction: column;
        margin-top: -2rem;
    }
    .plan{
        transform: scale(0.8);
        margin-right: 3.4rem;
        margin-top: -2rem; 
    }
    .plans>:nth-child(2){
        transform: scale(0.93);
    }
}